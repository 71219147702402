.div {
    padding-top: 80px;

    
    & #firstSection {
        position: relative;
        padding: 100px 20% 50px 20%;

        display: flex;
        align-items: none;

        background-color: #e6e6e6;

        font-size: 30px;
        font-weight: 600;


        & .icon {
            position: absolute;
            margin-top: 13px;
            left: 40px;
            width: 40px;
            height: 40px;


            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }

        & span {
            padding: 0 30px;
            
            border-left: solid 3px #fea621;

            font-size: 40px;
        }
    }

    & #secondSection {
        margin: 0 0 100px 0;


        & div {
            margin: 0 auto;
            width: 60%;


            & #image {
                margin-top: 50px;
                width: 100%;
            }

            & .icon {
                width: 30px;
            }

            & section {
                margin: 30px 0;
            }

            & #info {
                padding: 30px 50px;
                position: relative;

                border-radius: 5px;

                font-weight: 600;

                cursor: pointer;
                transition: all .25s linear;


                & img {
                    position: absolute;
                    top: 10px;
                    right: 10px;

                    transition: all .25s linear;
                }
            }

            & #container {
                margin: 0 auto;
                width: 170px;
            }

            & a {
                padding: 10px 5px;

                border-radius: 5px;

                background-color: #74b7d6;
                color: white;

                text-decoration: none;


                &:hover {
                    background-color: #51aad3;

                    cursor: pointer;
                }

                & img {
                    margin: -3px 0 0 12px;

                    width: 22px;
                }

                & span {
                    margin: 15px;

                    font-weight: 600;
                }
            }
        }
    }
}


.p {
    width: 100%;

    color: #639cb6;
    text-align: center;
    font-size: 25px;
}