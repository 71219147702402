.div {
    text-align: center;
}

.img {
    display: block;
    margin: 0 auto;
    width: 35px;
    animation: loadIconRotation infinite 1s linear;


    @keyframes loadIconRotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
}