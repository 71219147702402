.footer {
    height: 60px;

    display: flex;
    align-items: center;
    justify-content: center;
    clear: left;

    background-color: #74b7d6;
    color: white;

    z-index: 10;
}