.div {
	position: relative;
    margin: 10px auto;
    padding-left: 15px;
    width: 1200px;
    height: 100px;

    display: flex;
    align-items: center;

    border-radius: 6px;
    box-shadow: 1px 1px 1px 1px rgb(89, 89, 89);
    background-color: white;
	
    transition: all .1s linear;


	&:hover {
        background-color: #f5f5f5;
    }
	&:active {
        background-color: #cbebff;
    }

	& #number {
		width: 30px;

		text-align: center;
	}

	& #title {
		width: 380px;
	}

	& #addressAndTime {
		width: 370px;

		white-space: nowrap;


		& div {
			margin-top: 3px;
			width: 170px;
			height: 32px;


			& #tilde {
				padding: 0 3px;
			}
		}

		& p {
			margin: 0;
		}
	}

	& #weather {
		width: 200px;
	}

	& #btnCross {
		padding: 3px;
		position: absolute;
		right: 10px;
		width: 36px;

		border-radius: 5px;


		&:hover {
			background-color: #e7e7e7;

			cursor: pointer;
		}
	}
}