.div {
	position: relative;
	margin: auto;
	margin-top: 80px;
	width: 100vw;
	background-color:rgb(215, 215, 215);


	& button {
		position: absolute;
		top: 0;
		width: 150px;
		height: 400px;

		border: none;
		background-color: transparent;
		background: linear-gradient(90deg, rgba(255, 255, 255, 0.514), transparent);

		opacity: 0.3;
		transition: all .2s linear;


		&:hover {
			opacity: 1;
		}
		& img {
			width: 70px;
		}
	}

	& #btnGoNext {
		right: 0;
		transform: rotate(180deg);
	}
}

.title {
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: cover;
}

.slideImage {
	width: 100vw;
	height: 400px;

	text-align: center;
	overflow: hidden;

	& .bkImage {
		width: 100%;
		height: 100%;

		position: fixed;

		background-size: 100%;
		filter: blur(10px);
		z-index: -1;
	}

	& .img {
		width: auto;
		height: auto;
		max-height: 500px;
	}
}
