.div {
	position: relative;
    margin: 0 auto;
	margin-top: -40px;
    width: 1200px;
    height: 30px;
		
	z-index: 1;


	& .container {
		margin-left: 880px;
		padding-left: 20px;
		width: 320px;
		height: 30px;

		display: flex;
		align-items: center;

		border-radius: 6px;
		box-shadow: 1px 1px 1px 1px rgb(89, 89, 89);
		background-color: white;

		transition: all .1s linear;


		&:hover {
			background-color: #f5f5f5;
		}

		& p {
			margin: 0;

			text-align: right;
		}

		& #distance {
			margin-left: 10px;
			width: 80px;
		}
		& #time {
			width: 70px;
		}
	}
}