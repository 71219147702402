.map_travel_icon,
.map_home_icon {
    top: -10px;
    left: -10px;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    box-shadow: 0px 3px 10px rgb(179, 179, 179);
}

.map_travel_icon:after,
.map_home_icon:after {
    display: block;
    position: relative;
    top: -33px;
    left: -5px;
    width: 30px;
    height: 30px;
    font-size: 20px;
}

.map_travel_icon:after {
    content: url(../../assets/map-travel-dot.svg);
}

.map_home_icon:after {
    content: url(../../assets/map-home-dot.svg);
}

.map_today_weather_title {
    line-height: 0px;
    padding-top: 15px;
    font-size: 15px;
}

.map_today_weather_values {
    line-height: 0px;
    padding-top: 3px;
    font-size: 12px;
}

.map_dot_title_link {
    font-size: 15px;
}

.map_dot_title_location,
.map_dot_home_values,
.map_dot_station_values {
    line-height: 0px;
    font-size: 12px;
}

.map_dot_station_title {
    display:flex;
    justify-content: center;
    line-height: 0px;
    padding-top: 10px;
    font-size: 23px;
}

.map_dot_home_title {
    text-align: center;
    line-height: 1px;
    font-size: 20px;
}

.div {
    position: relative;
}

.map_mapping_to_google_map_but{
    position: absolute;
    left: 10px;
    top: 75px;
    width: 34px;
    height: 34px;

    border-radius: 5px;
    border: solid 2px #c0c0c0;
    background-color: white;

    z-index: 2;
}
.map_mapping_to_google_map_but:hover {
    background-color: #eeeeee;
    cursor: pointer;
}
.space{
    height: 5px;
}

.googleMapIcon{
    margin: 1px 5.4px;
    width: 20px;
    height: 28px;
}