.calendar {
    height: 80px;
    margin: 40px 20px 0 0;
    margin-right: 50px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    user-select: none;

    & .years {
        & input{
            border: none;
            background-color: transparent;
            display: inline;


            &:hover {
                background-color: #e4e4e4;
            }
        }
        & h5 {
            display: inline;
            margin: 0 10px 0 10px;
            font-weight: bold;
            line-height: 30px;
        }
    }

    & .season {
        & a{
            padding: 10px 20px;

            color: rgb(164, 164, 164) !important;
            
            text-shadow: 1px 0 0 rgb(164, 164, 164);
            text-decoration: none;
            font-family: Arial, sans-serif;
            font-size: 20px;


            &:hover {
                background-color: #f1f1f1;
            }
        }
    }
}