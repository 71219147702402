.border {
	border: 3px solid orange;
	box-sizing: border-box;
}

.card {
	-moz-user-select: none;//
	-webkit-user-select: none;//
	-ms-user-select: none;//
	user-select: none;//
	box-shadow: 3px 3px 3px 3px rgba(46, 46, 46, 0.556);

	& .block {
		width: 100%;
		height: 250px;
		
		position: absolute;
		background-color: rgba(173, 173, 173, 0.468);
	
		display: flex;
		align-items: center;

		user-select: none;
	
		& div {
			width: 100%;
	
			background-color: rgba(81, 81, 81, 0.562);
	
			text-align: center;
			font-size: 35px;
		}
	}
	& .noneBlock {
		display: none;
		user-select: none;
	}
	
	& .title {
		overflow: hidden;
		font-family: 'fantasy', Georgia, 'Times New Roman', Times, serif;
		user-select: text;
	}
	
	& .button {
		display: flex;
		justify-content: center;
		user-select: none;
	
		& img {
			width: auto;
			height: 25px;
			cursor: pointer;
		}
	
		& .bar {
			height: 30px;
			margin: 0 25% 0 25%;
			cursor: auto;
		}
	
		& .imgAdd {
			transform: rotate(0deg);
			transition: transform 1s;
		}
	
		& .imgClose {
			transform: rotate(315deg);
			transition: transform 1s;
		}
	}
	
}
